* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.ant-col.ant-form-item-label{
  max-width: none;
}
.container {
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  width: 100vw;
}
.page-wrapper {
  display: flex;
  flex-direction: row;
}
.subContainer {
  padding: 0.5rem 0.5rem;
  /* width: calc(100% - 229.333px); */
  padding-bottom: 3rem;
  overflow-y: scroll;
  height: 95vh;
}
.twoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}
.threeColumn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

.profile-container {
  padding: 0.5rem 0.5rem;
  width: calc(100% - 229.333px);
  padding-bottom: 3rem;
  overflow-y: scroll;
  height: 95vh;
}
.data-container {
  height: max-content;
  max-height: 90vh;
  margin: 0.5rem;
  background-color: #ffffff;
  overflow: auto;
  padding: 0.5rem 0.5rem;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 3rem;
  /* max-height: max-content; */
}

.form-container {
  /* max-height: 90vh; */
  margin: 1rem;
  background-color: #ffffff;
  margin-top: 1rem;
  overflow: scroll;
  padding: 1rem 1rem;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 3rem;
  max-height: max-content;
}
.data-container-work-flow {
  height: 95vh;
  margin: auto;
  background-color: #ffffff;
  margin-top: 1rem;
  overflow: scroll;
  padding: 1rem 1rem;
  padding-bottom: 3rem;
  width: 60%;
}

.import-data-form .jsonForm .ant-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* .ant-table-tbody > tr > td {
  width: max-content;
  white-space: nowrap;
} */

::-webkit-scrollbar {
  width: 8px;
  height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-upload-list-item-info{
  width: 100%;
  max-width: 300px;
}