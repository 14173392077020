.common-flex-row {
    display: flex;
  }
  
  .common-flex-col {
    display: flex;
    flex-direction: column;
  }
  
  .align-center {
    align-items: center;
  }
  .align-end {
    align-items: flex-end;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .margin-t {
    margin-top: 1rem;
  }
  /* user creation */
  .user-creation-form {
    background-color: white;
  }
  
  .add-btns {
    gap: 1rem;
  }
  
  .main-container {
    /* display: grid;
    grid-template-columns: 1fr 7fr;
    justify-content: space-around;
    padding-top: 1rem; */
    position: fixed;
    padding: 1rem 1rem;
    width: 100%;
    display: flex;
    gap: 1rem;
  }
  
  /* roles form  */
  .roles-input {
    gap: 0.5rem;
  }
  
  .menu-style {
    height: 100vh;
    width: 200px;
    position: fixed;
    background-color: rebeccapurple;
  }
  
  #form-input-container {
    display: flex;
    flex-direction: column;
  }
  
  .validation-text {
    position: absolute;
    color: #faad14;
    bottom: -1.2rem;
    left: 0;
  }
  .validation-text-roles{
    margin-top: 0.5rem;
    color:#faad14;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  @media screen and (max-width: 600px) {
    .main-container {
      display: flex;
      flex-direction: column;
    }
  }